import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import { CheckToken } from '../helpers/utils'
import DashboardLayout from '../layouts/dashboardlayout'
import SearchContactsCard from '../components/contacts/searchContactsCard'
import DisplayContacts from '../components/contacts/downloadContactsCard'

const Contacts = () => {
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Contacts</title>
      </Helmet>
      <DashboardLayout>
        <>
          <DisplayContacts />
          <SearchContactsCard />
        </>
      </DashboardLayout>
    </>
  )
}

export default Contacts
