import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardImg, CardTitle, CardText, Button, Spinner } from 'reactstrap'
import CountUp from 'react-countup';
import Typed from 'react-typed';
import background1 from '../../assets/img/VinoDirect-WineryPortal-ContactHeader.jpg'
import { requestGetWithKey, dowloadFile, randomMessage, typedDelay } from '../../helpers/utils'

const DisplayContacts = () => {
  const [contactCount, setContactCount] = useState(null)
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null)

  useEffect(() => {
    setLoadingMessage(randomMessage())
    const contacts = [`api/zoho/contacts/count`]
    requestGetWithKey(contacts).then(result => {
      setContactCount(result.message?.count)
    })
  }, [])

  const exportContacts = () => {
    setLoading(true);
    dowloadFile([`api/zoho/contacts/download`]).finally(() => setLoading(false));
  }
  return contactCount ? (
    <>
      <Card className="cardHeight" id="yesExportContacts">
        <CardImg src={background1} />
        <CardBody className="contactBody">
          {!loading ?
            <>
              <CountUp
                end={contactCount}
                duration={1}
                separator=","
                className="cardCount card-title"
              />
              <CardText tag="p" className="cardSubText">
                Contacts ready to export!
              </CardText>
              <Button id="exportContactsButton" className="btn btn-vino btn-vino-primary" style={{ width: '200px' }} onClick={exportContacts}>
                Export all Contacts
              </Button>
            </> :
            <div className="text-center p-4">
              <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
            </div>}
        </CardBody>
      </Card>
    </>
  ) : (
    <>
      <Card className="cardHeight" id="exportContacts">
        <CardBody className="contactBody">
          <CardTitle tag="h5" className="cardCount cardLight">
            {loadingMessage}
          </CardTitle>
          {contactCount === null ? (
            <Typed
              id='noExportOrders'
              strings={[`searching for contacts${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.`]}
              className="cardSubText cardLight"
              showCursor={false}
              typeSpeed={20}
              loop
            />
          ) : (
            <CardText tag="p" className="cardSubText cardLight" id="noExportContacts">
              No lists to export, yet.
            </CardText>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default DisplayContacts