import React, { useEffect, useState } from 'react'
import { Card, CardBody, Input, InputGroup, InputGroupAddon, Button, UncontrolledTooltip, Spinner } from 'reactstrap'
import Select from 'react-select'
import Typed from "react-typed"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import { requestGetWithKey, dowloadFile } from '../../helpers/utils'

const SearchContactsCard = () => {
  const [campaigns, setCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [contacts, setContacts] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [loadingMessageLong, setLoadingMessageLong] = useState(false);
  const [loadingMessageTooLong, setLoadingMessageTooLong] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  useEffect(() => {
    const getCampaigns = [`api/zoho/campaigns`]
    requestGetWithKey(getCampaigns)
      .then(result => {
        let campaignList = result.message.data.map(d => {
          return { value: d.id, label: d.Campaign_Name }
        })
        setCampaigns(campaignList)
      })
      .catch(error => {})
  }, [])
  const loadMessage = () => {
    setTimeout(() => {
      setLoadingMessage(true)
      setLoadingMessageTooLong(false)
      setLoadingMessageLong(false)
    }, 0);
    setTimeout(() => {
      setLoadingMessageLong(true)
      setLoadingMessage(false)
      setLoadingMessageTooLong(false)
    }, 20000);
  }

  const search = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    let searchQuery = '?limit=200'
    if (searchValue) {
      searchQuery += `&searchValue=${searchValue}`
    }
    let getContacts = [`api/zoho/contacts${searchQuery}`]
    if (selectedCampaign) {
      getContacts = [`api/zoho/campaigns/${selectedCampaign.value}/contacts${searchQuery}`]
    }
    requestGetWithKey(getContacts)
      .then(result => {
        setContacts(result.message.data)
      })
      .catch(error => {})
      .finally(() => setLoading(false))
  }

  const exportList = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setErrorMessage(null)
    let searchQuery = ''
    if (searchValue) {
      searchQuery = `?searchValue=${searchValue}`
    }
    let getContacts = [`api/zoho/contacts/download${searchQuery}`]
    if (selectedCampaign) {
      getContacts = [`api/zoho/campaigns/${selectedCampaign.value}/contacts/download${searchQuery}`]
    }
    dowloadFile(getContacts).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }
          setErrorMessage(error.data.msg)
          setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }

  const exportRow = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setErrorMessage(null)
    let getContacts = [`api/zoho/contacts/download?searchValue=${row.id}&singleContact=true`]
    dowloadFile(getContacts).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
    .catch((error) => {
      if (error === 503) {
        setLoadingMessageTooLong(true)
      }
      if (error === 404) {
        setEmptyMessage(true)
        setErrorMessage(null)
      }
      if (error.statusCode === 500) {
        setErrorMessage(error.body.data.msg)
        setEmptyMessage(false)
      }
      setErrorMessage(error.data.msg)
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
  }

  return (
    <Card className="border-bottom">
      <CardBody className="overflow-hidden p-lg-4 card-inside" style={{ height: 'unset', minHeight: '72vh' }}>
        <h1>Search contact</h1>
        <div className="text-left mb-2">
          <div className="d-inline-block w-50 w-lg-25 pr-4">
            <Select
              options={campaigns}
              value={selectedCampaign}
              isClearable
              onChange={value => setSelectedCampaign(value)}
              classNamePrefix="react-select"
              id="selectSearch"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: 0,
                  height: 'calc(1.5em + 0.625rem + 2px)',
                  border: '1px solid #d8e2ef !important',
                  borderRadius: '0.25rem !important',
                  '&:hover': {
                    border: '1px solid #d8e2ef !important',
                    borderRadius: '0.25rem !important',
                  },
                }),
              }}
            />
          </div>
          <div className="d-inline-block w-50 w-lg-25 pr-4">
            <Input
              placeholder="Search name, email, mobile..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyPress={e => (e.key === 'Enter' && (searchValue || selectedCampaign) ? search() : null)}
              id="searchInput"
            />
          </div>
          <div className="d-inline-block pr-4">
            <Button
              id="searchBtn1"
              className="ml-auto px-5 btn-vino btn-vino-primary"
              transform="down-1 shrink-4"
              onClick={() => search()}
              disabled={!searchValue && !selectedCampaign}
            >
              Search
            </Button>
          </div>
          <div className="d-inline-block pr-4">
            <Button
              id="searchBtn2"
              className="ml-auto px-5 btn-vino btn-vino-primary"
              transform="down-1 shrink-4"
              style={{ width: 260 }}
              onClick={() => exportList()}
              disabled={!searchValue && !selectedCampaign}
            >
              Export Search results
            </Button>
          </div>
        </div>
        {
          !loadingMessageTooLong ?
          !loading && !errorMessage && contacts && contacts.length > 0 && (
          <>
            {contacts.length >= 200 && <span>Displaying first 200 records</span>}
            <BootstrapTable
              id="searchContactsTable"
              bootstrap4
              keyField="id"
              data={contacts}
              headerClasses="bs-table-header"
              rowClasses="bs-table-row"
              columns={[
                {
                  dataField: 'Full_Name',
                  text: 'Name',
                  sort: true,
                },
                {
                  dataField: 'Email',
                  text: 'Email',
                  sort: true,
                },
                {
                  dataField: 'Mobile',
                  text: 'Phone 1',
                  sort: true,
                },
                {
                  dataField: 'Phone',
                  text: 'Phone 2',
                  sort: true,
                },
                {
                  dataField: 'Last_Campaign_Name',
                  text: 'Last Campaign Name',
                  sort: true,
                },
                {
                  dataField: 'Last_Call_Outcome',
                  text: 'Last Call Outcome',
                  sort: true,
                },
                {
                  dataField: 'Customer_Segment',
                  text: 'Customer Segment',
                  sort: true,
                },
                {
                  isDummyField: true,
                  dataField: 'actions',
                  text: 'Actions',
                  sort: false,
                  formatter: (cell, row, index) => (
                    <>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="text-secondary"
                        onClick={() => exportRow(row)}
                        id={'exportIcon' + index}
                        style={{ cursor: 'pointer' }}
                      />
                      <UncontrolledTooltip placement="bottom" target={'exportIcon' + index}>
                        Export
                      </UncontrolledTooltip>
                    </>
                  ),
                },
              ]}
              defaultSorted={[
                {
                  dataField: 'Full_Name',
                  order: 'asc',
                },
              ]}
              defaultSortDirection="asc"
            />
          </>
          ) :
          <p className="loadingText">
            <span>Looks like the server has run out of patience and is having a time-out. Take a deep breath and try again. But if you see this a couple of times make a screenshot and send us an email because it’s probably more serious than just a grumpy or busy server
            </span>
            </p>
        }
        {!loading && !errorMessage && !contacts && (
          <div className="text-center" id="searchContactsCardMessages1">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">What can we get for you?</h1>
            <p className="mb-1 fs-1">Try searching for something containing</p>
            <p className="mb-1 fs-1">First name, Last name, Email, Mobile, Phone, Member ID</p>
          </div>
        )}
        {!loading && !errorMessage && contacts && contacts.length === 0 && (
          <div className="text-center" id="searchContactsCardMessages2">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">Oops, we didn't find anything</h1>
            <p className="mb-1 fs-1">Try searching for something containing</p>
            <p className="mb-1 fs-1">First name, Last name, Email, Mobile, Phone, Member ID</p>
            <p className="mb-2 fs-1">or check spelling mistakes.</p>
            <h2 className="mb-4 fs-2">Try again.</h2>
          </div>
        )}
        {!loading && !errorMessage && !contacts && errorMessage && !emptyMessage &&
          <p className="loadingText"><span>{errorMessage}</span></p>
        }
        {loading && (
          <>
          <div className="text-center pt-6">
            <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
          </div>
           {loadingMessage ?
            <Typed
              strings={["Please wait, we’re building your file for you. Should take less time than decanting a nice red..."]}
              typeSpeed={10}
              className="loadingText"
            />
            : null}
           {loadingMessageLong ?
            <Typed
              strings={["Well, this is embarrassing. Maybe the server needs more coffee and less wine…? (We’re still waiting though - hopefully won’t be long now)"]}
              typeSpeed={10}
              className="loadingText"
            />
            : null}
      </>
        )}
      </CardBody>
    </Card>
  )
}

export default SearchContactsCard
